<template>
<v-overlay v-if="showUpgradeDialog">
    <v-card class="upgrade-plan-dialog pa-5"
        max-width="700"
        outlined
        light
    >
        <v-card-subtitle class="subtitle warning--text pb-0">
            <v-icon
                class="mr-3 warning--text"
            >error_outline</v-icon>
            THIS IS AN ADD-ON FEATURE
        </v-card-subtitle>
        <v-card-title class="pa-0 primary--text">
            <v-card-text>
                <h1 class="pl-1">Sync more than just inventory.</h1>
            </v-card-text>
        </v-card-title>
        <v-card-actions>
            <v-card-text class="pt-0 pb-0">
                Our product sync add-on allows you to sync product and variant attributes such as title, description, images, and much more.
                <a 
                    class="learn-more"
                    target="__blank" 
                    hreh="#"
                >Learn More.</a>
            </v-card-text>
        </v-card-actions>
        <v-card-actions>
            <v-card-text>
                <v-img
                    class="ma-auto"
                    src="@/assets/images/not-available.jpg"
                    width="50%"
                ></v-img>
            </v-card-text>
        </v-card-actions>
        <v-card-actions>
            <v-card-text>
                <div
                    class="float-left" 
                    @click="showUpgradeDialog = !showUpgradeDialog"
                >Back to Products</div>
                <a class="float-right"
                >
                    <v-btn
                        class="primary white--text upgrade-plan"
                        outlined
                    >Upgrade Plan</v-btn>
                </a>
            </v-card-text>
        </v-card-actions>
    </v-card>
</v-overlay>
</template>
<script>
export default {
    name: "ProductSyncConfigUpgradeDialog",
    data:function() {
        return {
            showUpgradeDialog: true,
        };
    },
}
</script>
<style lang="scss" scoped>
.upgrade-plan-dialog {
    margin: auto;
    .subtitle {
        font-weight: 700;
    }
    .learn-more {
        color: #1E88E5;
        font-weight: 600;
    }
    .upgrade-plan {
        padding: 10px 40px;
    }
}
</style>